import { useEffect, useState } from "react";
import { MapContainer, useMap } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

function GeoJSONLayer({ geoData, geoJSONStyle, onEachFeature }) {
  const map = useMap();

  useEffect(() => {
    if (!geoData) return;
    // Remove existing layers before adding new ones
    const layerGroup = L.layerGroup();
    const geoJsonLayer = L.geoJSON(geoData, {
      style: geoJSONStyle,
      onEachFeature: onEachFeature,
    });

    layerGroup.addLayer(geoJsonLayer);
    layerGroup.addTo(map);

    // Clean up previous layers on unmount or when geoData changes
    return () => {
      layerGroup.clearLayers();
    };
  }, [geoData, map]);

  return null;
}

export default function HistoricMap() {
  const earliestDate = new Date(1886, 0, 1);
  const latestDate = new Date(2019, 11, 31);


  const [allGeoData, setAllGeoData] = useState(null);
  const [geoData, setGeoData] = useState(null);
  const [mapDate, setMapDate] = useState(latestDate);
  const [ogMapDate, setOgMapDate] = useState(latestDate);
  const [mapEarlyLimit, setMapEarlyLimit] = useState(earliestDate)
  const [mapLaterLimit, setMapLaterLimit] = useState(latestDate)
  const [mapEarlyCountry, setMapEarlyCountry] = useState(null)
  const [mapLaterCountry, setMapLaterCountry] = useState(null)
  const [showEarlyYear, setShowEarlyYear] = useState(false)
  const [showLaterYear, setShowLaterYear] = useState(false)
  const [showEarlyDate, setShowEarlyDate] = useState(false)
  const [showLaterDate, setShowLaterDate] = useState(false)
  const [showEarlyCountry, setShowEarlyCountry] = useState(false)
  const [showLaterCountry, setShowLaterCountry] = useState(false)
  const [showMapDate, setShowMapDate] = useState(false)
  const [userEarlyDate, setUserEarlyDate] = useState('1886-01-01')
  const [userLaterDate, setUserLaterDate] = useState('2019-12-31')
  const [earlyHint, setEarlyHint] = useState('')
  const [showEarlyHint, setShowEarlyHint] = useState(false)
  const [laterHint, setLaterHint] = useState('')
  const [showLaterHint, setShowLaterHint] = useState(false)



  useEffect(() => {
    fetch('/historic_data.geojson')
      .then((response) => response.json())
      .then((data) => setAllGeoData(data));
  }, []);

  useEffect(() => {
    if (!allGeoData) return;
    const filteredData = allGeoData.features.filter(country => {
      const startDate = new Date(country.properties.gwsyear, country.properties.gwsmonth - 1, country.properties.gwsday);
      const endDate = new Date(country.properties.gweyear, country.properties.gwemonth - 1, country.properties.gweday);
      return mapDate >= startDate && mapDate <= endDate;
    });
    setGeoData({ type: "FeatureCollection", features: filteredData });

    // below: calculating when the latests changes were
    let earlyLimit = earliestDate
    let earlyCountry = ''
    let laterLimit = latestDate
    let laterCountry = ''
    filteredData.forEach(country => {
      const startDate = new Date(country.properties.gwsyear, country.properties.gwsmonth - 1, country.properties.gwsday);
      const endDate = new Date(country.properties.gweyear, country.properties.gwemonth - 1, country.properties.gweday);
      if (startDate >= earlyLimit) {
        earlyLimit = startDate
        earlyCountry = country.properties.cntry_name
      }
      if (endDate <= laterLimit) {
        laterLimit = endDate
        laterCountry = country.properties.cntry_name
      }
    });
    setMapEarlyLimit(earlyLimit)
    setMapEarlyCountry(earlyCountry)
    setMapLaterLimit(laterLimit)
    setMapLaterCountry(laterCountry)
  }, [mapDate, allGeoData]);

  const randomizeDate = () => {
    const randomDate = new Date(+earliestDate + Math.random() * (latestDate - earliestDate));
    setMapDate(randomDate);
    setOgMapDate(randomDate);

    setShowEarlyYear(false)
    setShowLaterYear(false)
    setShowEarlyCountry(false)
    setShowLaterCountry(false)
    setShowEarlyDate(false)
    setShowLaterDate(false)
    setShowMapDate(false)
    setShowEarlyHint(false)
    setShowLaterHint(false)
  };

  // Function to generate a random color
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Style function to assign different colors
  const geoJSONStyle = (feature) => {
    return {
      fillColor: getRandomColor(),
      weight: 2,
      opacity: 1,
      color: 'white',
      fillOpacity: 0.7
    };
  };

  // Function to add labels to each country
  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties.cntry_name) {
      layer.bindTooltip(feature.properties.cntry_name, { permanent: false, direction: 'top' });
    }
  };

  useEffect( () => {
    if (!mapEarlyLimit || !mapLaterLimit || !userEarlyDate || !userLaterDate) return;

    const mSecondsInDay = 1000*60*60*24
    const parsedUserEarlyDate = Date.parse(userEarlyDate)
    const parsedUserLaterDate = Date.parse(userLaterDate)
    const earlyDiff = Math.abs(parsedUserEarlyDate - mapEarlyLimit.getTime())
    const laterDiff = Math.abs(parsedUserLaterDate - mapLaterLimit.getTime())
    const earlyDiffAddition = (mapEarlyLimit.getTime() - parsedUserEarlyDate) < 0 ? ' - OVER RESTRICTED' : ''
    const laterDiffAddition = (parsedUserLaterDate - mapLaterLimit.getTime()) < 0 ? ' - OVER RESTRICTED' : ''
    
    let earlyHint = 'Greater than 50 years off'
    let laterHint = 'Greater than 50 years off'

    if (earlyDiff < mSecondsInDay * 365.25 * 50) earlyHint = 'within 50 years'
    if (laterDiff < mSecondsInDay * 365.25 * 50) laterHint = 'within 50 years'
    if (earlyDiff < mSecondsInDay * 365.25 * 10) earlyHint = 'within 10 years'
    if (laterDiff < mSecondsInDay * 365.25 * 10) laterHint = 'within 10 years'
    if (earlyDiff < mSecondsInDay * 365.25 * 5) earlyHint = 'within 5 years'
    if (laterDiff < mSecondsInDay * 365.25 * 5) laterHint = 'within 5 years'
    if (earlyDiff < mSecondsInDay * 365.25) earlyHint = 'within 1 year'
    if (laterDiff < mSecondsInDay * 365.25) laterHint = 'within 1 year'
    if (earlyDiff < mSecondsInDay * 365.25 / 2) earlyHint = 'within 6 months'
    if (laterDiff < mSecondsInDay * 365.25 / 2) laterHint = 'within 6 months'
    if (earlyDiff < mSecondsInDay * 30) earlyHint = 'within 30 days'
    if (laterDiff < mSecondsInDay * 30) laterHint = 'within 30 days'
    if (earlyDiff < mSecondsInDay * 7) earlyHint = 'within a week'
    if (laterDiff < mSecondsInDay * 7) laterHint = 'within a week'
    if (earlyDiff <= mSecondsInDay*1.1) earlyHint = 'correctDay'
    if (laterDiff <= mSecondsInDay*1.1) laterHint = 'correctDay'

    setEarlyHint(earlyHint + earlyDiffAddition)
    setLaterHint(laterHint + laterDiffAddition)
    //!Here 
  }, [userLaterDate, userEarlyDate, mapDate])

  const handleMapReturn = () => {
    setMapDate(ogMapDate)
  }

  const handleLaterMap = () => {
    const laterLimit = new Date(mapLaterLimit)
    laterLimit.setDate(mapLaterLimit.getDate() + 1)
    setMapDate(laterLimit)
  }

  const handleEarlierMap = () => {
    const earlyLimit = new Date(mapEarlyLimit)
    earlyLimit.setDate(mapEarlyLimit.getDate() - 1)
    setMapDate(earlyLimit)
  }

  return (
    <div>
      <MapContainer center={[0,0]} zoom={2} style={{ height: '750px' }}>
        {geoData && (
          <GeoJSONLayer geoData={geoData} geoJSONStyle={geoJSONStyle} onEachFeature={onEachFeature} />
        )}
      </MapContainer>
      <div>
        Range Guess 
        <input type="date" value={userEarlyDate} onChange={(event) => setUserEarlyDate(event.target.value)} /> - 
        <input type="date" value={userLaterDate} onChange={(event) => setUserLaterDate(event.target.value)} />
      </div>
      <div>
        <button onClick={() => setShowEarlyHint(!showEarlyHint)}>Show Early Hint</button>
        {showEarlyHint ? earlyHint : ''}
        <br />
        <button onClick={() => setShowLaterHint(!showLaterHint)}>Show Later Hint</button>
        {showLaterHint ? laterHint : ''}
        <br />
        <button onClick={() => setShowEarlyYear(!showEarlyYear)}>Show Early Date Year</button>
        {showEarlyYear ? mapEarlyLimit.getFullYear() : ''}
        <br />
        <button onClick={() => setShowLaterYear(!showLaterYear)}>Show Later Date Year</button>
        {showLaterYear ? mapLaterLimit.getFullYear() : ''}
        <br />
        <button onClick={() => setShowEarlyCountry(!showEarlyCountry)}>Show Early Country</button>
        {showEarlyCountry ? mapEarlyCountry : ''}
        <br />
        <button onClick={() => setShowLaterCountry(!showLaterCountry)}>Show Later Country</button>
        {showLaterCountry ? mapLaterCountry : ''}
        <br />
        <button onClick={() => setShowEarlyDate(!showEarlyDate)}>Show Early Date</button>
        {showEarlyDate ? mapEarlyLimit.toISOString().split('T')[0] : ''}
        <br />
        <button onClick={() => setShowLaterDate(!showLaterDate)}>Show Later Date</button>
        {showLaterDate ? mapLaterLimit.toISOString().split('T')[0] : ''}
        <br />
        <button onClick={() => setShowMapDate(!showMapDate)}>Show Map Date</button>
        {showMapDate ? mapDate.toISOString().split('T')[0] : ''}
        <br />
      </div>

      <br />
      <div>
        {mapDate.getDate() != ogMapDate.getDate() && <button onClick={handleMapReturn}>Back</button> }
        <div>
          <button onClick={handleEarlierMap}>Show Previous Map</button><button onClick={handleLaterMap}>Show Later Map</button><br />
        </ div>
        
      </div>

      <br/>
      <button onClick={randomizeDate}>Random Date</button>
    </div>
  );
}
