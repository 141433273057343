const countries = {
  "Albania": { "Continent": "EU", "Borders": ["Greece", "Montenegro", "North Macedonia", "Serbia"] },
  "Andorra": { "Continent": "EU", "Borders": ["France", "Spain"] },
  "Austria": { "Continent": "EU", "Borders": ["Czech Republic (Czechia)", "Germany", "Hungary", "Italy", "Liechtenstein", "Slovakia", "Slovenia", "Switzerland"] },
  "Argentina": { "Continent": "SA", "Borders": ["Bolivia", "Brazil", "Chile", "Paraguay", "Uruguay"] },
  "Algeria": { "Continent": "AF", "Borders": ["Libya", "Mali", "Mauritania", "Morocco", "Niger", "Tunisia", "Western Sahara"] },
  "Angola": { "Continent": "AF", "Borders": ["Republic of the Congo", "Democratic Republic of the Congo", "Namibia", "Zambia"] },
  "Afghanistan": { "Continent": "AS", "Borders": ["China", "Iran", "Pakistan", "Tajikistan", "Turkmenistan", "Uzbekistan"] },
  "Armenia": { "Continent": "AS", "Borders": ["Azerbaijan", "Georgia", "Iran", "Türkiye"] },
  "Azerbaijan": { "Continent": "AS", "Borders": ["Armenia", "Georgia", "Iran", "Russia", "Türkiye"] },
  "Belarus": { "Continent": "EU", "Borders": ["Latvia", "Lithuania", "Poland", "Russia", "Ukraine"] },
  "Belgium": { "Continent": "EU", "Borders": ["France", "Germany", "Luxembourg", "Netherlands"] },
  "Bosnia and Herzegovina": { "Continent": "EU", "Borders": ["Croatia", "Montenegro", "Serbia"] },
  "Bulgaria": { "Continent": "EU", "Borders": ["Greece", "North Macedonia", "Romania", "Serbia", "Türkiye"] },
  "Belize": { "Continent": "NA", "Borders": ["Guatemala", "Mexico"] },
  "Bolivia": { "Continent": "SA", "Borders": ["Argentina", "Brazil", "Chile", "Paraguay", "Peru"] },
  "Brazil": { "Continent": "SA", "Borders": ["Argentina", "Bolivia", "Colombia", "French Guiana", "Guyana", "Paraguay", "Peru", "Suriname", "Uruguay", "Venezuela"] },
  "Benin": { "Continent": "AF", "Borders": ["Burkina Faso", "Niger", "Nigeria", "Togo"] },
  "Botswana": { "Continent": "AF", "Borders": ["Namibia", "South Africa", "Zambia", "Zimbabwe"] },
  "Burkina Faso": { "Continent": "AF", "Borders": ["Benin", "Ivory Coast", "Ghana", "Mali", "Niger", "Togo"] },
  "Burundi": { "Continent": "AF", "Borders": ["Democratic Republic of the Congo", "Rwanda", "Tanzania"] },
  "Bahrain": { "Continent": "AS", "Borders": [] },
  "Bangladesh": { "Continent": "AS", "Borders": ["India", "Myanmar"] },
  "Bhutan": { "Continent": "AS", "Borders": ["China", "India"] },
  "Brunei": { "Continent": "AS", "Borders": ["Malaysia"] },
  "Croatia": { "Continent": "EU", "Borders": ["Bosnia and Herzegovina", "Hungary", "Montenegro", "Serbia", "Slovenia"] },
  "Cyprus": { "Continent": "EU", "Borders": [] },
  "Czech Republic (Czechia)": { "Continent": "EU", "Borders": ["Austria", "Germany", "Poland", "Slovakia"] },
  "Canada": { "Continent": "NA", "Borders": ["United States"] },
  "Costa Rica": { "Continent": "NA", "Borders": ["Nicaragua", "Panama"] },
  "Cuba": { "Continent": "NA", "Borders": [] },
  "Chile": { "Continent": "SA", "Borders": ["Argentina", "Bolivia", "Peru"] },
  "Colombia": { "Continent": "SA", "Borders": ["Brazil", "Ecuador", "Panama", "Peru", "Venezuela"] },
  "Cameroon": { "Continent": "AF", "Borders": ["Central African Republic", "Chad", "Republic of the Congo", "Equatorial Guinea", "Gabon", "Nigeria"] },
  "Cape Verde": { "Continent": "AF", "Borders": [] },
  "Central African Republic": { "Continent": "AF", "Borders": ["Cameroon", "Chad", "Republic of the Congo", "Democratic Republic of the Congo", "South Sudan", "Sudan"] },
  "Chad": { "Continent": "AF", "Borders": ["Cameroon", "Central African Republic", "Libya", "Niger", "Nigeria", "Sudan"] },
  "Comoros": { "Continent": "AF", "Borders": [] },
  "Cambodia": { "Continent": "AS", "Borders": ["Laos", "Thailand", "Vietnam"] },
  "China": { "Continent": "AS", "Borders": ["Afghanistan", "Bhutan", "Hong Kong", "India", "Kazakhstan", "North Korea", "Kyrgyzstan", "Laos", "Macao", "Mongolia", "Myanmar", "Nepal", "Pakistan", "Russia", "Tajikistan", "Vietnam"] },
  "Denmark": { "Continent": "EU", "Borders": ["Germany"] },
  "Dominican Republic": { "Continent": "NA", "Borders": ["Haiti"] },
  "Democratic Republic of the Congo": { "Continent": "AF", "Borders": ["Angola", "Burundi", "Central African Republic", "Republic of the Congo", "Rwanda", "South Sudan", "Tanzania", "Uganda", "Zambia"] },
  "Djibouti": { "Continent": "AF", "Borders": ["Eritrea", "Ethiopia", "Somalia"] },
  "Estonia": { "Continent": "EU", "Borders": ["Latvia", "Russia"] },
  "El Salvador": { "Continent": "NA", "Borders": ["Guatemala", "Honduras"] },
  "Ecuador": { "Continent": "SA", "Borders": ["Colombia", "Peru"] },
  "Egypt": { "Continent": "AF", "Borders": ["Israel", "Libya", "Palestine", "Sudan"] },
  "Equatorial Guinea": { "Continent": "AF", "Borders": ["Cameroon", "Gabon"] },
  "Eritrea": { "Continent": "AF", "Borders": ["Djibouti", "Ethiopia", "Sudan"] },
  "Eswatini": { "Continent": "AF", "Borders": ["Mozambique", "South Africa"] },
  "Ethiopia": { "Continent": "AF", "Borders": ["Djibouti", "Eritrea", "Kenya", "Somalia", "South Sudan", "Sudan"] },
  "East Timor": { "Continent": "AS", "Borders": ["Indonesia", ""] },
  "Finland": { "Continent": "EU", "Borders": ["Norway", "Russia", "Sweden"] },
  "France": { "Continent": "EU", "Borders": ["Andorra", "Belgium", "Germany", "Italy", "Luxembourg", "Monaco", "Spain", "Switzerland"] },
  "French Guiana": { "Continent": "SA", "Borders": ["Brazil", "Suriname"] },
  "Germany": { "Continent": "EU", "Borders": ["Austria", "Belgium", "Czech Republic (Czechia)", "Denmark", "France", "Luxembourg", "Netherlands", "Poland", "Switzerland"] },
  "Greece": { "Continent": "EU", "Borders": ["Albania", "Bulgaria", "North Macedonia", "Türkiye"] },
  "Guatemala": { "Continent": "NA", "Borders": ["Belize", "El Salvador", "Honduras", "Mexico"] },
  "Guyana": { "Continent": "SA", "Borders": ["Brazil", "Suriname", "Venezuela"] },
  "Gabon": { "Continent": "AF", "Borders": ["Cameroon", "Republic of the Congo", "Equatorial Guinea"] },
  "Ghana": { "Continent": "AF", "Borders": ["Burkina Faso", "Ivory Coast", "Togo"] },
  "Guinea": { "Continent": "AF", "Borders": ["Ivory Coast", "Guinea-Bissau", "Liberia", "Mali", "Senegal", "Sierra Leone"] },
  "Guinea-Bissau": { "Continent": "AF", "Borders": ["Guinea", "Senegal"] },
  "Georgia": { "Continent": "AS", "Borders": ["Armenia", "Azerbaijan", "Russia", "Türkiye"] },
  "Hungary": { "Continent": "EU", "Borders": ["Austria", "Croatia", "Romania", "Serbia", "Slovakia", "Slovenia", "Ukraine"] },
  "Haiti": { "Continent": "NA", "Borders": ["Dominican Republic"] },
  "Honduras": { "Continent": "NA", "Borders": ["El Salvador", "Guatemala", "Nicaragua"] },
  "Iceland": { "Continent": "EU", "Borders": [] },
  "Ireland": { "Continent": "EU", "Borders": ["United Kingdom"] },
  "Italy": { "Continent": "EU", "Borders": ["Austria", "France", "San Marino", "Slovenia", "Switzerland", "Holy See"] },
  "Ivory Coast": { "Continent": "AF", "Borders": ["Burkina Faso", "Ghana", "Guinea", "Liberia", "Mali"] },
  "India": { "Continent": "AS", "Borders": ["Bangladesh", "Bhutan", "China", "Myanmar", "Nepal", "Pakistan"] },
  "Indonesia": { "Continent": "AS", "Borders": ["Malaysia", "Papua New Guinea", "East Timor"] },
  "Iran": { "Continent": "AS", "Borders": ["Afghanistan", "Armenia", "Azerbaijan", "Iraq", "Pakistan", "Türkiye", "Turkmenistan"] },
  "Iraq": { "Continent": "AS", "Borders": ["Iran", "Jordan", "Kuwait", "Saudi Arabia", "Syria", "Türkiye"] },
  "Israel": { "Continent": "AS", "Borders": ["Egypt", "Jordan", "Lebanon", "Palestine", "Syria"] },
  "Jamaica": { "Continent": "NA", "Borders": [] },
  "Japan": { "Continent": "AS", "Borders": [] },
  "Jordan": { "Continent": "AS", "Borders": ["Iraq", "Israel", "Palestine", "Saudi Arabia", "Syria"] },
  "Kosovo": { "Continent": "EU", "Borders": ["Northern Macedonia", "Serbia", "Albania", "Montenegro"] },
  "Kenya": { "Continent": "AF", "Borders": ["Ethiopia", "Somalia", "South Sudan", "Tanzania", "Uganda"] },
  "Kazakhstan": { "Continent": "AS", "Borders": ["China", "Kyrgyzstan", "Russia", "Turkmenistan", "Uzbekistan"] },
  "Kuwait": { "Continent": "AS", "Borders": ["Iraq", "Saudi Arabia"] },
  "Kyrgyzstan": { "Continent": "AS", "Borders": ["China", "Kazakhstan", "Tajikistan", "Uzbekistan"] },
  "Latvia": { "Continent": "EU", "Borders": ["Belarus", "Estonia", "Lithuania", "Russia"] },
  "Liechtenstein": { "Continent": "EU", "Borders": ["Austria", "Switzerland"] },
  "Lithuania": { "Continent": "EU", "Borders": ["Belarus", "Latvia", "Poland", "Russia"] },
  "Luxembourg": { "Continent": "EU", "Borders": ["Belgium", "Germany", "France"] },
  "Lesotho": { "Continent": "AF", "Borders": ["South Africa"] },
  "Liberia": { "Continent": "AF", "Borders": ["Ivory Coast", "Guinea", "Sierra Leone"] },
  "Libya": { "Continent": "AF", "Borders": ["Algeria", "Chad", "Egypt", "Niger", "Sudan", "Tunisia"] },
  "Laos": { "Continent": "AS", "Borders": ["China", "Cambodia", "Myanmar", "Thailand", "Vietnam"] },
  "Lebanon": { "Continent": "AS", "Borders": ["Israel", "Syria"] },
  "Malta": { "Continent": "EU", "Borders": [] },
  "Moldova": { "Continent": "EU", "Borders": ["Romania", "Ukraine"] },
  "Monaco": { "Continent": "EU", "Borders": ["France"] },
  "Montenegro": { "Continent": "EU", "Borders": ["Albania", "Bosnia and Herzegovina", "Croatia", "Serbia"] },
  "Mexico": { "Continent": "NA", "Borders": ["Belize", "Guatemala", "United States"] },
  "Madagascar": { "Continent": "AF", "Borders": [] },
  "Malawi": { "Continent": "AF", "Borders": ["Mozambique", "Tanzania", "Zambia"] },
  "Mali": { "Continent": "AF", "Borders": ["Algeria", "Burkina Faso", "Ivory Coast", "Guinea", "Mauritania", "Niger", "Senegal"] },
  "Mauritania": { "Continent": "AF", "Borders": ["Algeria", "Mali", "Senegal", "Western Sahara"] },
  "Mauritius": { "Continent": "AF", "Borders": [] },
  "Morocco": { "Continent": "AF", "Borders": ["Algeria", "Spain", "Western Sahara"] },
  "Mozambique": { "Continent": "AF", "Borders": ["Malawi", "Eswatini", "South Africa", "Tanzania", "Zambia", "Zimbabwe"] },
  "Malaysia": { "Continent": "AS", "Borders": ["Brunei", "Indonesia", "Thailand"] },
  "Maldives": { "Continent": "AS", "Borders": [] },
  "Mongolia": { "Continent": "AS", "Borders": ["China", "Russia"] },
  "Myanmar": { "Continent": "AS", "Borders": ["Bangladesh", "China", "India", "Laos", "Thailand"] },
  "Netherlands": { "Continent": "EU", "Borders": ["Belgium", "Germany"] },
  "North Macedonia": { "Continent": "EU", "Borders": ["Albania", "Bulgaria", "Greece", "Serbia"] },
  "Norway": { "Continent": "EU", "Borders": ["Finland", "Russia", "Sweden"] },
  "Nicaragua": { "Continent": "NA", "Borders": ["Costa Rica", "Honduras"] },
  "Namibia": { "Continent": "AF", "Borders": ["Angola", "Botswana", "South Africa", "Zambia"] },
  "Niger": { "Continent": "AF", "Borders": ["Algeria", "Benin", "Burkina Faso", "Chad", "Libya", "Mali", "Nigeria"] },
  "Nigeria": { "Continent": "AF", "Borders": ["Benin", "Cameroon", "Chad", "Niger"] },
  "Nepal": { "Continent": "AS", "Borders": ["China", "India"] },
  "North Korea": { "Continent": "AS", "Borders": ["China", "South Korea", "Russia"] },
  "Oman": { "Continent": "AS", "Borders": ["United Arab Emirates", "Saudi Arabia", "Yemen"] },
  "Poland": { "Continent": "EU", "Borders": ["Belarus", "Czech Republic (Czechia)", "Germany", "Lithuania", "Russia", "Slovakia", "Ukraine"] },
  "Portugal": { "Continent": "EU", "Borders": ["Spain"] },
  "Panama": { "Continent": "NA", "Borders": ["Colombia", "Costa Rica"] },
  "Paraguay": { "Continent": "SA", "Borders": ["Argentina", "Bolivia", "Brazil"] },
  "Peru": { "Continent": "SA", "Borders": ["Bolivia", "Brazil", "Chile", "Colombia", "Ecuador"] },
  "Pakistan": { "Continent": "AS", "Borders": ["Afghanistan", "China", "India", "Iran"] },
  "Philippines": { "Continent": "AS", "Borders": [] },
  "Qatar": { "Continent": "AS", "Borders": ["Saudi Arabia"] },
  "Romania": { "Continent": "EU", "Borders": ["Bulgaria", "Hungary", "Moldova", "Serbia", "Ukraine"] },
  "Russia": { "Continent": "EU", "Borders": ["Azerbaijan", "Belarus", "China", "Estonia", "Finland", "Georgia", "Kazakhstan", "North Korea", "Latvia", "Lithuania", "Mongolia", "Norway", "Poland", "Ukraine"] },
  "Republic of the Congo": { "Continent": "AF", "Borders": ["Angola", "Cameroon", "Central African Republic", "Democratic Republic of the Congo", "Gabon"] },
  "Rwanda": { "Continent": "AF", "Borders": ["Burundi", "Democratic Republic of the Congo", "Tanzania", "Uganda"] },
  "Russia": { "Continent": "AS", "Borders": ["Azerbaijan", "Belarus", "China", "Estonia", "Finland", "Georgia", "Kazakhstan", "North Korea", "Latvia", "Lithuania", "Mongolia", "Norway", "Poland", "Ukraine"] },
  "San Marino": { "Continent": "EU", "Borders": ["Italy"] },
  "Serbia": { "Continent": "EU", "Borders": ["Albania", "Bosnia and Herzegovina", "Bulgaria", "Croatia", "Hungary", "Montenegro", "North Macedonia", "Romania"] },
  "Slovakia": { "Continent": "EU", "Borders": ["Austria", "Czech Republic (Czechia)", "Hungary", "Poland", "Ukraine"] },
  "Slovenia": { "Continent": "EU", "Borders": ["Austria", "Croatia", "Hungary", "Italy"] },
  "Spain": { "Continent": "EU", "Borders": ["Andorra", "France", "Gibraltar", "Morocco", "Portugal"] },
  "Sweden": { "Continent": "EU", "Borders": ["Finland", "Norway"] },
  "Switzerland": { "Continent": "EU", "Borders": ["Austria", "France", "Germany", "Italy", "Liechtenstein"] },
  "Suriname": { "Continent": "SA", "Borders": ["Brazil", "French Guiana", "Guyana"] },
  "Senegal": { "Continent": "AF", "Borders": ["The Gambia", "Guinea", "Guinea-Bissau", "Mali", "Mauritania"] },
  "Seychelles": { "Continent": "AF", "Borders": [] },
  "Sierra Leone": { "Continent": "AF", "Borders": ["Guinea", "Liberia"] },
  "Somalia": { "Continent": "AF", "Borders": ["Djibouti", "Ethiopia", "Kenya"] },
  "South Africa": { "Continent": "AF", "Borders": ["Botswana", "Lesotho", "Mozambique", "Namibia", "Eswatini", "Zimbabwe"] },
  "South Sudan": { "Continent": "AF", "Borders": ["Central African Republic", "Democratic Republic of the Congo", "Ethiopia", "Kenya", "Sudan", "Uganda"] },
  "Sudan": { "Continent": "AF", "Borders": ["Central African Republic", "Chad", "Egypt", "Ethiopia", "Eritrea", "Libya", "South Sudan"] },
  "São Tomé and Príncipe": { "Continent": "AF", "Borders": [] },
  "Saudi Arabia": { "Continent": "AS", "Borders": ["Iraq", "Jordan", "Kuwait", "Oman", "Qatar", "United Arab Emirates", "Yemen"] },
  "Singapore": { "Continent": "AS", "Borders": [] },
  "South Korea": { "Continent": "AS", "Borders": ["North Korea"] },
  "Sri Lanka": { "Continent": "AS", "Borders": [] },
  "Syria": { "Continent": "AS", "Borders": ["Iraq", "Israel", "Jordan", "Lebanon", "Türkiye"] },
  "Tanzania": { "Continent": "AF", "Borders": ["Burundi", "Democratic Republic of the Congo", "Kenya", "Malawi", "Mozambique", "Rwanda", "Uganda", "Zambia"] },
  "The Gambia": { "Continent": "AF", "Borders": ["Senegal"] },
  "Togo": { "Continent": "AF", "Borders": ["Benin", "Burkina Faso", "Ghana"] },
  "Tunisia": { "Continent": "AF", "Borders": ["Algeria", "Libya"] },
  "Taiwan": { "Continent": "AS", "Borders": [] },
  "Tajikistan": { "Continent": "AS", "Borders": ["Afghanistan", "China", "Kyrgyzstan", "Uzbekistan"] },
  "Thailand": { "Continent": "AS", "Borders": ["Cambodia", "Laos", "Malaysia", "Myanmar"] },
  "Turkmenistan": { "Continent": "AS", "Borders": ["Afghanistan", "Iran", "Kazakhstan", "Uzbekistan"] },
  "Türkiye": { "Continent": "AS", "Borders": ["Armenia", "Azerbaijan", "Bulgaria", "Georgia", "Greece", "Iran", "Iraq", "Syria"] },
  "Ukraine": { "Continent": "EU", "Borders": ["Belarus", "Hungary", "Moldova", "Poland", "Romania", "Russia", "Slovakia"] },
  "United Kingdom": { "Continent": "EU", "Borders": ["Ireland"] },
  "United States": { "Continent": "NA", "Borders": ["Canada", "Mexico"] },
  "Uruguay": { "Continent": "SA", "Borders": ["Argentina", "Brazil"] },
  "Uganda": { "Continent": "AF", "Borders": ["Democratic Republic of the Congo", "Kenya", "Rwanda", "South Sudan", "Tanzania"] },
  "United Arab Emirates": { "Continent": "AS", "Borders": ["Oman", "Saudi Arabia"] },
  "Uzbekistan": { "Continent": "AS", "Borders": ["Afghanistan", "Kazakhstan", "Kyrgyzstan", "Tajikistan", "Turkmenistan"] },
  "Vatican City": { "Continent": "EU", "Borders": ["Italy"] },
  "Venezuela": { "Continent": "SA", "Borders": ["Brazil", "Colombia", "Guyana"] },
  "Vietnam": { "Continent": "AS", "Borders": ["Cambodia", "China", "Laos"] },
  "Western Sahara": { "Continent": "AF", "Borders": ["Algeria", "Mauritania", "Morocco"] },
  "Yemen": { "Continent": "AS", "Borders": ["Oman", "Saudi Arabia"] },
  "Zambia": { "Continent": "AF", "Borders": ["Angola", "Botswana", "Democratic Republic of the Congo", "Malawi", "Mozambique", "Namibia", "Tanzania", "Zimbabwe"] },
  "Zimbabwe": { "Continent": "AF", "Borders": ["Botswana", "Mozambique", "South Africa", "Zambia"] }
}

export default countries