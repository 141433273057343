import React, {useState, useEffect} from "react";
import StartingLetterQuiz from "../StartingLetterQuiz";
import BoardersQuiz from "./CountryBoarderQuiz";
import HistoricMap from "./HistoricMap";

const CountryQuizzes = () => {
  const [quiz, setQuiz] = useState('')

  return (
      <div>
        {quiz == '' && <button onClick={() =>setQuiz('startingLetter')}>Starting Letter</button>}
        <br />
        {quiz == '' && <button onClick={() =>setQuiz('boarders')}>Borders</button>}
        <br />
        {quiz == '' && <button onClick={() =>setQuiz('historicMap')}>HistoricMap</button>}
        {quiz == 'startingLetter' && <StartingLetterQuiz />}
        {quiz == 'boarders' && <BoardersQuiz />}
        {quiz == 'historicMap' && <HistoricMap />}
        {quiz != '' && <button onClick={() =>setQuiz('')}>Back</button>}
      </div>
  )
}

export default CountryQuizzes