import React, {useState, useEffect} from "react";
import countries from './CountryInfo'

const BoardersQuiz = () => {
  const [country, setCountry] = useState('')
  const [countryList, setCountryList] = useState([]);
  const [numCountries, setNumCountries] = useState(0);
  const [firstLetters, setFirstLetters] = useState({});
  const [showAns, setShowAns] = useState(false);
  const [showNumCountries, setShowNumCountries] = useState(false);
  const [showFirstLetters, setShowFirstLetters] = useState(false);
  const [guess, setGuess] = useState('');
  const [guessedCountries, setGuessedCountries] = useState([]);

  const setRandomCountry = () => {
    const countryEntries = Object.entries(countries)
    const [newCountry, details] = countryEntries[Math.floor(Math.random() * countryEntries.length)]
    setCountry(newCountry)
    setCountryList(details['Borders'])
    setNumCountries(details['Borders'].length)
  }

  useEffect(() => {
    if (country != '' && country) {
      setShowFirstLetters(false)
      setShowNumCountries(false)
      setShowAns(false)
      setGuessedCountries([])
      setGuess('')

      const firstLettersBreakdown = countryList.reduce((acc, country) => {
        if (acc[country[0]]) {
          acc[country[0]] = acc[country[0]]+1 
        } else {
          acc[country[0]] = 1
        }
        return acc
      }, {})
      setFirstLetters(firstLettersBreakdown)
    }
  }, [country])

  const handleGuess = () => {
    if (!guessedCountries.includes(guess) && guess != '') {
      setGuessedCountries([...guessedCountries, guess])
      setGuess('')
    } else {
      if (guessedCountries.includes(guess)) {
        setGuessedCountries(guessedCountries.filter(item => item !==guess))
      }
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleGuess(event)
    }
  }

  const handleShowNum = () => {
    setShowNumCountries(!showNumCountries)
  }

  const handleShowCont = () => {
    setShowFirstLetters(!showFirstLetters)
  }

  const handleShowAns = () => {
    setShowAns(!showAns)
  }



  return (
    <div>
      <h1>Country Quiz - {country ?? ''}</h1>
      <button onClick={setRandomCountry}>Random Country</button>
      <br />
      <button onClick={handleShowNum}>Show Number of Countries</button> 
      {showNumCountries ? numCountries : ''}
      <br />
      <button onClick={handleShowCont}>Show First Letter Breakdown</button> 
      {showFirstLetters ? Object.entries(firstLetters).map((cont, index) => (<li key={index}>{cont[0]+':'+cont[1]}</li>)) : ''}
      <br />
      <button onClick={handleShowAns}>Show Answer</button> 
      {showAns ? Object.values(countryList).map((country, index) => (<li key={index}>{country}</li>)) : ''}
      <br />
      <input 
        type="text"
        value={guess}
        onChange={(e) => setGuess(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Enter Guess"
        />
      <button onClick={handleGuess}>Submit</button>
      <div />
      <h2>Guessed Countries: </h2>
      <u1>
        {guessedCountries.map((country, index) => (
            <li key={index}>{country}</li>
          ))}
      </u1>

    </div>
  )
}

export default BoardersQuiz