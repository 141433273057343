import React, {useState, useEffect} from "react";
import countries from './country_quizzes/CountryInfo'

const StartingLetterQuiz = () => {
  const [letter, setLetter] = useState('')
  const [countryList, setCountryList] = useState([]);
  const [numCountries, setNumCountries] = useState(0);
  const [continents, setContinents] = useState({});
  const [showAns, setShowAns] = useState(false);
  const [showNumCountries, setShowNumCountries] = useState(false);
  const [showCont, setShowCont] = useState(false);
  const [guess, setGuess] = useState('');
  const [guessedCountries, setGuessedCountries] = useState([]);

    // Function to pick a random letter
  const randomLetter = () => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWYZ';
    return alphabet[Math.floor(Math.random() * alphabet.length)];
  };

  const setRandomLetter = () => {
    const newLetter = randomLetter()
    setLetter(newLetter)
  }

  useEffect(() => {
    if (letter != '' && letter) {
      setShowCont(false)
      setShowNumCountries(false)
      setShowAns(false)
      setGuessedCountries([])
      setGuess('')
      
      const filteredCountries = Object.keys(countries).filter(country => country.startsWith(letter));
      setCountryList(filteredCountries)
      setNumCountries(filteredCountries.length)

      const continentsBreakdown = filteredCountries.reduce((acc, country) => {
        if (acc[countries[country]['Continent']]) {
          acc[countries[country]['Continent']] = acc[countries[country]['Continent']]+1 
        } else {
          acc[countries[country]['Continent']] = 1
        }
        return acc
      }, {})
      setContinents(continentsBreakdown)
    }
  }, [letter])

  const handleGuess = () => {
    if (!guessedCountries.includes(guess) && guess != '') {
      setGuessedCountries([...guessedCountries, guess])
      setGuess('')
    } else {
      if (guessedCountries.includes(guess)) {
        setGuessedCountries(guessedCountries.filter(item => item !==guess))
      }
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleGuess(event)
    }
  }

  const handleShowNum = () => {
    setShowNumCountries(!showNumCountries)
  }

  const handleShowCont = () => {
    setShowCont(!showCont)
  }

  const handleShowAns = () => {
    setShowAns(!showAns)
  }



  return (
    <div>
      <h1>Country Quiz - {letter ?? ''}</h1>
      <button onClick={setRandomLetter}>RandomLetter</button>
      <br />
      <button onClick={handleShowNum}>Show Number of Countries</button> 
      {showNumCountries ? numCountries : ''}
      <br />
      <button onClick={handleShowCont}>Show Continent Breakdown</button> 
      {showCont ? Object.entries(continents).map((cont, index) => (<li key={index}>{cont[0]+':'+cont[1]}</li>)) : ''}
      <br />
      <button onClick={handleShowAns}>Show Answer</button> 
      {showAns ? Object.values(countryList).map((country, index) => (<li key={index}>{country}</li>)) : ''}
      <br />
      <input 
        type="text"
        value={guess}
        onChange={(e) => setGuess(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Enter Guess"
        />
      <button onClick={handleGuess}>Submit</button>
      <div />
      <h2>Guessed Countries: </h2>
      <u1>
        {guessedCountries.map((country, index) => (
            <li key={index}>{country}</li>
          ))}
      </u1>

    </div>
  )
}

export default StartingLetterQuiz